<template>
	<div class="h-full">
		<Upload ref="upload" @done="updateMatch" />
		<div v-if="user && match" class="match-overview flex flex-col h-full">
			<!--already applied for this job-->
			<div
				v-if="
					(!viewOnly && (match.likeuser === 'pending' || match.likeuser === 'true')) ||
					alreadyApplied
				"
				class="text-center container px-4 mt-8"
			>
				<HokIcon name="icon:checked" :size="20" color="main" class="inline mb-4" />
				<h3>
					Du hast dich bereits für
					{{ match.relation && match.relation.type === 'job' ? 'diesen Job' : 'diese Firma' }}
					beworben!
				</h3>
				<HokButton fullwidth="mobile" to="/pwa" color="main"> Weitere Jobs entdecken </HokButton>
			</div>

			<!--show overview (all data) of current application-->
			<div v-else-if="match && match.relation" class="overflow-y-auto fancy-scrollbar flex-1">
				<div v-if="viewOnly && user.testerGroup === 'A'" class="container flex justify-center pt-4">
					<HokButton color="main" to="/pwa" fullwidth="mobile"> Weitere Jobs entdecken</HokButton>
				</div>
				<h1 v-if="match.relation.type === 'job'" class="smaller px-4 text-center mt-4 break-words">
					Bewerbungsvorschau
				</h1>
				<h1 v-else class="smaller px-4 text-center mt-4 break-words">
					Initiativbewerbung für die Firma {{ match.relation.obj.name }}
				</h1>
				<div class="container max-w-5xl mt-4 mb-8 break-words pb-8">
					<ErrorBox
						v-if="
							!viewOnly &&
							(hasMissingProfilePicture ||
								hasMissingAttachments ||
								hasMissingCv ||
								hasMissingProfileVideo)
						"
						class="mx-4"
					>
						<template #title> Daten vervollständigen </template>
						<span v-if="hasMissingProfilePicture">
							Dir fehlt noch ein Foto, um deine Bewerbung abschicken zu können. <br />
						</span>
						<span v-if="hasMissingCv">
							Dir fehlt noch ein Lebenslauf, um deine Bewerbung abschicken zu können. <br />
						</span>
						<span v-if="amountMissingAttachments > 1">
							Dir fehlen noch {{ amountMissingAttachments }} Dokumente, um deine Bewerbung
							abschicken zu können.<br />
						</span>
						<span v-else-if="amountMissingAttachments === 1">
							Dir fehlt noch 1 Dokument, um deine Bewerbung abschicken zu können. <br />
						</span>
						<span v-if="hasMissingProfileVideo">
							Dir fehlt noch ein Video, um deine Bewerbung abschicken zu können. <br />
						</span>
						<template #action>
							<HokButton
								fullwidth="mobile"
								:submit="false"
								class="mt-4"
								color="main"
								@click="restartInterview"
							>
								Bewerbung vervollständigen
							</HokButton></template
						>
					</ErrorBox>
					<div :class="{ 'flex space-x-10': !$isMobile.any }">
						<section :class="{ 'w-5/12 relative': !$isMobile.any }">
							<div
								v-if="
									!viewOnly &&
									!hasMissingProfilePicture &&
									!hasMissingAttachments &&
									!hasMissingCv &&
									!hasMissingProfileVideo
								"
								class="flex justify-center"
							>
								<div class="mb-4 p-6 bg-color-main rounded-lg text-color-white">
									<p class="text-xl mb-3 font-bold">Alles Wichtige drin? 👀</p>
									<p class="mb-0">
										Lies noch einmal ganz über deine Bewerbung, bevor du sie abschickst!
									</p>
								</div>
							</div>

							<div
								class="bg-color-white rounded-lg pt-6 mb-4 text-center text-color-white shadow-xl"
							>
								<ProfileImageEditMode
									v-if="!viewOnly"
									id="pic"
									:user="user"
									class="mb-6 cursor-none"
									color="none"
								/>
								<ProfileImage v-else id="pic" :user="user" class="mb-6" color="none" />
								<CvUserGeneralOverview
									id="cv-user-general-overview"
									:user="user"
									:view-only="viewOnly"
								/>
							</div>
							<div
								v-if="!$isMobile.any"
								id="cv"
								class="bg-color-white rounded-lg pt-6 px-6 mb-4 shadow-xl sticky top-2"
								:class="{ 'sticky top-4': !$isMobile.any }"
							>
								<div class="text-start flex justify-between items-center mb-8">
									<h4 class="mb-0">Lebenslauf</h4>
								</div>
								<ErrorBox v-if="!viewOnly && hasMissingCv">
									<div>Dir fehlt noch ein Lebenslauf, um deine Bewerbung abschicken zu können.</div>
								</ErrorBox>
								<div v-if="visibleCv">
									<div class="pb-12">
										<div class="flex justify-between mb-2">
											<HokButton class="font-bold" is-text color="main" @click="toggleMenu">
												{{ activeHokifyCv ? 'hokify Lebenslauf' : visibleCv.title }}
											</HokButton>
											<HokIcon
												v-if="!viewOnly"
												id="cv-edit-icon"
												name="icon:pencil"
												color="main"
												:size="5"
												pointer
												class="inline self-center"
												@click="toggleMenu"
											/>
										</div>
										<p class="text-xs mb-0 text-color-grey-medium">
											Hochgeladen {{ fromNow(visibleCv.date) }}
										</p>
									</div>
								</div>
								<div v-else class="flex flex-col items-center">
									<HokButton color="main" fullwidth="always" class="mb-5" @click="setCv('hokify')">
										Profil als Lebenslauf verwenden</HokButton
									>
									<p class="mb-4 text-color-grey">oder</p>
									<HokButton
										color="main"
										is-text
										class="flex space-x-2 pb-10"
										@click="setCv('new-upload')"
									>
										<HokIcon
											name="icon:upload"
											:size="6"
											new-icon
											color="main"
											data-cy="uploadCv"
										/>
										<span class="text-color-main"> Lebenslauf hochladen </span>
									</HokButton>
								</div>
							</div>
						</section>
						<section id="cv" :class="{ 'w-7/12': !$isMobile.any }">
							<CvExperiencesEditMode :view-only="viewOnly" />

							<CvEducationsEditMode id="cv-edit-element" :view-only="viewOnly" />

							<CvSkillsEditMode id="cv-skills-element" :view-only="viewOnly" />

							<div id="cv-observer-element" class="h-[1px]" />

							<div v-if="$isMobile.any" class="bg-color-white rounded-lg pt-6 px-6 mb-4 shadow-xl">
								<div class="text-start flex justify-between items-center mb-8">
									<h4 class="mb-0">Lebenslauf</h4>
								</div>
								<ErrorBox v-if="!viewOnly && hasMissingCv">
									<template
										>Dir fehlt noch ein Lebenslauf, um deine Bewerbung abschicken zu
										können.</template
									>
								</ErrorBox>

								<div v-if="visibleCv">
									<div class="cursor-pointer pb-12">
										<div class="flex justify-between mb-2">
											<HokButton class="font-bold" is-text color="main" @click="toggleMenu">
												{{ activeHokifyCv ? 'hokify Lebenslauf' : visibleCv.title }}
											</HokButton>
											<HokIcon
												id="cv-edit-icon"
												name="icon:pencil"
												color="main"
												:size="5"
												pointer
												class="inline self-center"
												@click="toggleMenu"
											/>
										</div>
										<p class="text-xs mb-0 text-color-grey-medium">
											Hochgeladen {{ fromNow(visibleCv.date) }}
										</p>
									</div>
								</div>
								<div v-else class="flex flex-col items-center -mx-2">
									<HokButton
										color="main"
										fullwidth="always"
										class="mb-5 -mx-2"
										@click="setCv('hokify')"
									>
										Profil als Lebenslauf verwenden</HokButton
									>
									<p class="mb-4 text-color-grey">oder</p>
									<HokButton
										is-text
										class="flex space-x-2 pb-10"
										color="main"
										@click="setCv('new-upload')"
									>
										<HokIcon name="icon:upload" :size="5" color="main" />
										<span class="text-color-main"> Lebenslauf hochladen </span>
									</HokButton>
								</div>
							</div>

							<div v-if="hasIndividualQuestions">
								<div class="bg-color-white rounded-lg shadow-xl px-6 mb-4 pb-8">
									<h4 class="pt-8 mb-6">Individuelle Abfragen</h4>
									<MatchQuestions
										:user="user"
										:match="internalMatch"
										:modal="modal"
										:modules="['open_question']"
										:view-only="viewOnly"
										profile-or-match-overview
										type="question"
										qkey="open"
										beautify-text
										@update-match="updateMatch"
									>
									</MatchQuestions>
									<MatchQuestions
										:user="user"
										:match="internalMatch"
										:modal="modal"
										:excl-modules="['open_question', 'distancehome']"
										:view-only="viewOnly"
										type="question"
										qkey="closed"
										profile-or-match-overview
										beautify-text
										@update-match="updateMatch"
									/>
								</div>
							</div>
							<div v-if="hasAttachments || hasAttachmentQuestions">
								<div class="bg-color-white rounded-lg shadow-xl px-6 mb-4 pb-8">
									<div class="flex justify-between pt-8 mb-6">
										<h4 class="mb-0">
											{{
												hasAttachmentQuestions ? 'Angefordete Dokumente' : 'Dokumente (optional)'
											}}
										</h4>
									</div>

									<ErrorBox v-if="hasMissingAttachments">
										<span v-if="amountMissingAttachments > 1">
											Dir fehlen noch {{ amountMissingAttachments }} Dokumente, um deine Bewerbung
											abschicken zu können.
										</span>
										<span v-else-if="amountMissingAttachments === 1">
											Dir fehlt noch 1 Dokument, um deine Bewerbung abschicken zu können.
										</span>
										<template #action
											><HokButton
												fullwidth="mobile"
												:submit="false"
												color="main"
												@click="restartInterview"
											>
												Bewerbung vervollständigen
											</HokButton></template
										>
									</ErrorBox>
									<MatchQuestions
										v-if="hasAttachments || hasAttachmentQuestions"
										:user="user"
										:match="internalMatch"
										:modal="modal"
										:view-only="viewOnly"
										type="attachment"
										qkey="att"
										profile-or-match-overview
										:excl-modules="['cv', 'profile-video']"
										beautify-text
										@update-match="updateMatch"
									/>
									<div v-else>
										<h4>Keine Anhänge vorhanden.</h4>
										<p>Du kannst optional noch Zeugnisse oder Ähnliches hinzufügen.</p>
									</div>
								</div>
							</div>
							<div class="h-1" data-cy="application-scroll"></div>
							<div
								v-if="profileVideoEnabled && (supportsVideoRecording || hasVideoInProfile)"
								id="video"
								data-cy="video-application"
								class="bg-color-white rounded-lg shadow-xl px-6 mb-4 pb-8"
							>
								<div class="flex justify-between pt-8 mb-6">
									<h4 class="mb-0">Videovorstellung</h4>
									<HokIcon
										v-if="!viewOnly && supportsVideoRecording && hasVideoInProfile"
										name="icon:pencil"
										:size="5"
										color="main"
										pointer
										@click="openVideoApplication"
									/>
								</div>
								<video
									v-if="hasVideoInProfile"
									:src="videoExtra && videoExtra.url"
									width="480"
									height="480"
									controls
									class="cursor-pointer rounded-lg"
									:class="{ 'bg-color-text': $isMobile.apple.device }"
								/>
								<div v-else>
									<p class="text-color-grey-medium mb-6 pb-2">
										Steigere deine Chancen auf einen Job indem du ein kurzes Video aufnimmst.
									</p>
									<div
										v-if="!viewOnly"
										class="flex flex-col items-center border-dashed border border-color-grey-light rounded-lg py-8 cursor-pointer"
										@click="openVideoApplication"
									>
										<div class="bg-color-grey-lightest p-4 rounded-full mb-4">
											<HokIcon name="icon:video-camera" :size="7" />
										</div>
										<HokButton color="main" is-text class="flex space-x-2">
											<HokIcon name="icon:add-no-circle" :size="5" new-icon color="main" />
											<span class="text-color-main"> Video aufnehmen</span>
										</HokButton>
									</div>
								</div>
							</div>
						</section>
					</div>
				</div>
			</div>
			<!--bottom buttons to save/apply-->
			<div
				v-if="
					!viewOnly &&
					!modalOpen &&
					match.likeuser !== 'pending' &&
					match.likeuser !== 'true' &&
					!alreadyApplied
				"
				class="buttons"
			>
				<div class="m-auto max-w-dinA4">
					<HorizontalButtons class="mb-4">
						<template #left>
							<HokButton
								outline
								:color="disabledButtons ? 'grey' : 'main'"
								fullwidth="mobile"
								@click="saveUser"
							>
								Bewerbung speichern
							</HokButton>
						</template>
						<template #right>
							<HokButton
								:color="disabledButtons ? 'grey' : 'main'"
								fullwidth="mobile"
								@click="apply"
							>
								Bewerbung versenden
							</HokButton>
						</template>
					</HorizontalButtons>
				</div>
			</div>
		</div>

		<!--wait to load user / match-->
		<div v-else class="container">
			<h4 class="center py-2">Bitte warten...</h4>
		</div>

		<!--user not verified yet - confirm via code-->
		<!-- nearly same modal is used in JobPublishAction.vue-->
		<!-- nearly same modal is used in CvGeneralData.vue-->
		<HokModal
			width="390px"
			:adaptive="true"
			name="user-not-verified"
			@opened="modalChange"
			@closed="modalChange"
		>
			<template v-if="match">
				<h3 class="text-center">Kontakt bestätigen</h3>
				<p class="text-center">
					Wir wollen sichergehen, dass du eine Antwort auf deine Bewerbung bekommen kannst.<br />Daher
					haben wir einen 4-stelligen Code an <br />
					<strong class="text-center block mb-2 sm:mt-2 break-all">{{
						(user && user.general && user.general.phone) ||
						(user && user.general && user.general.email)
					}}</strong>
					gesendet. Bitte gib den Code unten ein, um fortzufahren.
				</p>
				<form class="text-center" @submit="verifyAccount($event)">
					<ErrorBox v-if="showInvalidCode"> Ungültiger Code! </ErrorBox>
					<HokInput
						id="verifycode"
						v-model="verifyCode"
						pattern="[0-9]*"
						styling="text-center"
						placeholder="Code hier eingeben"
						inputmode="numeric"
						name="code"
						:max-length="6"
						:min-length="4"
						:autofocus="true"
						class="mb-2"
						@change="codeChanged"
						@click="codeChanged"
					/>
					<HokButton id="submitverifycode" fullwidth="always" submit color="main" class="mb-4"
						>Bestätigen</HokButton
					>
					<HokButton is-text color="main" fullwidth="always" class="mb-10" @click="changeContact">
						Kontakt ändern</HokButton
					>
					<HokButton
						v-if="!resentVerification"
						id="resendverifycode"
						class="mt-4"
						is-text
						color="main"
						@click="resendVerification"
					>
						Code erneut zusenden
					</HokButton>
				</form>
			</template>
		</HokModal>

		<!--data incomplete - update data before applying-->
		<HokModal
			adaptive
			name="profile-not-complete"
			@opened="modalChange"
			@closed="modalChange"
			@before-open="setModalError"
		>
			<div v-if="modalError" class="text-center">
				<h3>Bewerbung fast vollständig!</h3>
				<p class="text-left">
					Dein Profil ist leider noch <strong>unvollständig</strong>!<br />
					Bitte ergänze deine Eingabe damit du deine Bewerbung an {{ companyName }} senden kannst.
				</p>
				<ul v-if="modalError.unansweredQuestions" class="text-left list-disc pl-6">
					<li
						v-for="unansweredQuestion in modalError.unansweredQuestions"
						:key="unansweredQuestion.id"
					>
						{{ unansweredQuestion.question }}
					</li>
				</ul>
				<br />
				<HokButton :submit="false" class="mb-4" color="main" @click="restartInterview">
					Eingaben ergänzen
				</HokButton>
			</div>
		</HokModal>

		<!--job just saved - download app-->
		<HokModal
			:adaptive="!!$isMobile.any"
			name="job-save-success"
			transition="scale"
			width="600px"
			@opened="modalChange"
			@closed="modalChange"
		>
			<div v-if="match && match.relation && match.relation.type === 'job'" class="modal-inner">
				<h3 class="text-center">Job erfolgreich gespeichert!</h3>
				<p>
					Deine Eingaben für den Job <b>"{{ match.relation.obj.name }}"</b> bei
					{{ companyName }} wurden erfolgreich gespeichert.
				</p>
				<div class="flex flex-col items-center">
					<HokButton fullwidth="mobile" class="mb-4" color="main" @click="jobSavedOnly">
						Weitere Jobs entdecken
					</HokButton>
					<HokButton
						color="main"
						is-text
						fullwidth="mobile"
						@click="$modal.hide('job-save-success')"
					>
						zurück zur Bewerbung
					</HokButton>
				</div>
			</div>
			<div
				v-else-if="match && match.relation && match.relation.type === 'company'"
				class="modal-inner"
			>
				<h3 class="text-center">Unternehmen erfolgreich gespeichert!</h3>
				<p>
					Deine Eingaben für die Initiativbewerbung bei {{ match.relation.obj.name }}
					wurden erfolgreich gespeichert.
				</p>
				<div class="flex flex-col items-center">
					<HokButton fullwidth="mobile" class="mb-4" color="main" @click="jobSavedOnly">
						Weitere Jobs entdecken
					</HokButton>
					<HokButton
						color="main"
						is-text
						fullwidth="mobile"
						@click="$modal.hide('job-save-success')"
					>
						zurück zur Bewerbung
					</HokButton>
				</div>
			</div>
		</HokModal>

		<!--application send successfully-->
		<HokModal
			:width="$isMobile.any ? '95%' : '500px'"
			adaptive
			classes="v--modal noPadding"
			name="job-like-success"
			transition="scale"
			@opened="modalChange"
			@closed="modalChange"
			@before-close="maybePreventClosing"
		>
			<NuxtImg
				v-if="profileProtectionModal"
				src="/svg/data-protection.svg"
				alt="Daten schützen"
				class="rounded-t w-full"
			/>
			<NuxtImg
				v-else
				src="/svg/paperplane.svg"
				alt="Bewerbung versendet"
				class="rounded-t mb-8 w-full"
			/>
			<div class="text-center px-8 pb-8">
				<div v-if="profileProtectionModal" class="text-center">
					<h3>Schütze deine Daten!</h3>
					<p>Deine erste Bewerbung ist erfolgreich versendet!</p>
					<p>
						Damit du den Status deiner Bewerbung einsehen kannst und deine persönlichen Daten
						geschützt sind, setze bitte ein Passwort.
					</p>
				</div>
				<div v-else-if="exitSurvey" class="flex flex-col items-center pb-4">
					<h6>{{ exitSurvey.question }}</h6>
					<div class="w-fit">
						<div
							v-for="(answer, index) in exitSurvey.answers"
							:key="index"
							class="text-left mx-8 text-sm w-fit"
						>
							<HokCheckbox
								:id="`exitSurvey-${index}`"
								v-model="answerModels[answer]"
								@change="updateExitSurveyAnswers(answer)"
							>
								{{ answer }}
							</HokCheckbox>
						</div>
					</div>
				</div>
				<div v-else class="pb-4">
					<template v-if="firstApplication">
						<h3>Bewerbung erfolgreich versendet!</h3>
						<p>
							Jetzt bist du bei der nächsten hokify <br />
							Bewerbung rund 60% schneller.
						</p>
					</template>
					<h3 v-else class="text-color-text">Deine Bewerbung wurde <br />versendet!</h3>
				</div>
				<template v-if="profileProtectionModal">
					<HokButton full-width="always" color="main" @click="protectProfile"
						>Profil sichern</HokButton
					>
				</template>
				<template v-else-if="testSMS && $isMobile.any && !isCordova">
					<p class="max-w-md mx-auto">
						Gratuliere<span v-if="user && user.general && user.general.firstName">
							{{ user.general.firstName }}</span
						>,<br />
						verfolge jetzt den Status deiner Bewerbung in der hokify App und bewirb dich einfach für
						weitere Jobs!
					</p>
					<div>
						<HokButton fullwidth="mobile" class="mb-4" to="/getapp?type=hokify-apply-success">
							Bewerbung in App verfolgen
						</HokButton>
					</div>
					<div>
						<HokButton color="main" fullwidth="mobile" is-text @click="applicationCompleted()">
							Weitere ohne App
						</HokButton>
					</div>
				</template>
				<template
					v-else-if="testSMS && !$isMobile.any && (!user || (user && !user.mobileAppInstalled))"
				>
					<p class="max-w-md mx-auto">
						Gratuliere<span v-if="user && user.general && user.general.firstName">
							{{ user.general.firstName }}</span
						>,<br />
						verfolge jetzt den Status deiner Bewerbung in der hokify App und bewirb dich einfach für
						weitere Jobs!
					</p>
					<form
						v-if="!smsSent"
						class="flex flex-col mx-auto max-w-xs"
						@submit.stop.prevent="handleSendLink()"
					>
						<EmailOrPhone
							id="phone"
							v-model="phoneNumber"
							name="phone"
							:phone-only="true"
							:browser-autocomplete="false"
						>
							Telefonnummer
						</EmailOrPhone>
						<ErrorBox v-if="showError"> Bitte prüfe die eingegebene Telefonnummer. </ErrorBox>
						<HokButton :disabled="isSending" color="main" submit>
							DOWNLOAD LINK ZUSENDEN
						</HokButton>
					</form>
					<div v-else-if="smsSent" class="text-center">
						<p>{{ phoneNumber }} wird in Kürze eine SMS erhalten.</p>
						<HokButton fullwidth="mobile" @click="applicationCompleted()">
							Weitere Jobs entdecken
						</HokButton>
					</div>
				</template>
				<template v-else-if="exitSurvey">
					<div>
						<HokButton color="main" @click="answerExitSurvey"> Weiter </HokButton>
					</div>
				</template>
				<template v-else>
					<div class="flex flex-col">
						<div class="flex justify-center">
							<HokButton class="mb-4" color="main" @click="applicationCompleted()">
								{{ firstApplication ? 'Ähnliche' : 'Weitere' }}
								Jobs finden
							</HokButton>
						</div>
						<div class="flex justify-center">
							<HokButton color="main" :is-text="true" @click="applicationCompleted(true)">
								Bewerbung ansehen
							</HokButton>
						</div>
					</div>
				</template>
			</div>
		</HokModal>

		<!--confirm privacy-->
		<HokModal
			:adaptive="!!$isMobile.any"
			name="accept-privacy-application"
			@opened="modalChange"
			@closed="modalChange"
			@before-open="setModalError"
		>
			<div class="mb-4">
				<h3 class="text-center">Datenschutzbestimmungen</h3>
				<HokCheckbox
					id="privacy_user_application_modal"
					v-model="acceptedPrivacyApplication"
					name="privacy_user_application"
				>
					Hiermit stimme ich dem Versand meiner Daten an Firmen, bei denen ich mich bewerbe, zu.
					<template #more>
						<HokLink to="/privacy#user-application" target="_blank" class="text-sm block">
							Datenschutzerklärung & Widerrufsrecht
						</HokLink>
					</template>
				</HokCheckbox>
				<ErrorBox v-if="privacyError">
					Bitte bestätige die Datenschutzerklärung und das Widerrufsrecht
				</ErrorBox>
			</div>
			<div class="flex justify-center">
				<HokButton fullwidth="mobile" class="mb-4" color="main" @click="applyDSGVO">
					Fortfahren
				</HokButton>
			</div>
		</HokModal>

		<!-- cv-fails -->
		<HokModal
			:adaptive="!!$isMobile.any"
			name="cv-fails"
			@opened="modalChange"
			@closed="modalChange"
			@before-open="setModalError"
		>
			<h3 class="text-center">Fast geschafft!</h3>
			<p>
				Super, du hast es fast geschafft. Um deine Bewerbung abzuschließen, vervollständige bitte
				deine Daten.
			</p>
			<div class="flex justify-center">
				<HokButton fullwidth="mobile" color="main" to="/pwa/review-profile/">
					Jetzt vervollständigen
				</HokButton>
			</div>
		</HokModal>

		<!--show general error-->
		<HokModal
			:adaptive="!!$isMobile.any"
			name="general-error"
			@opened="modalChange"
			@closed="modalChange"
			@before-open="setModalError"
		>
			<div v-if="modalError">
				<div v-if="modalError.code === 'JOB_OFFLINE'" class="text-center">
					<h3>Bewerbung nicht möglich!</h3>
					<p>
						Deine Bewerbung konnte nicht übermittelt werden, da der Job nicht mehr verfügbar ist.
					</p>
					<HokButton fullwidth="mobile" color="main" to="/pwa">Weitere Jobs entdecken</HokButton>
				</div>
				<div
					v-if="
						modalError.code === 'INCOMPLETE_HOKIFY_CV' &&
						modalError.info &&
						modalError.info.missing &&
						modalError.info.missing.length > 0
					"
					class="text-center"
				>
					<h3>Bewerbung nicht möglich!</h3>
					<p>
						Deine Bewerbung konnte nicht übermittelt werden, da dein Lebenslauf nicht vollständig
						ist. Bitte ergänze folgende Punkte, um die Bewerbung versenden zu können:
					</p>
					<ul class="list-disc text-left pl-5 mb-4">
						<li v-for="missing in modalError.info.missing" :key="missing" class="mb-2">
							{{ errorCodes.cvErrors[missing] }}
						</li>
					</ul>
					<HokButton fullwidth="mobile" color="main" @click="$modal.hide('general-error')"
						>Eingaben vervollständigen</HokButton
					>
				</div>
				<div v-else-if="modalError.code === 'COMPANY_SPAM_PAUSED'" class="text-center">
					<h3>Mehrmalige Bewerbung</h3>
					<p>
						Bewerbungen für {{ companyName }} vorläufig blockiert. Bitte sende vorerst keine
						weiteren Bewerbungen an diese Firma. Du kannst dich aber weiterhin für andere Firmen
						bewerben. Bei Fragen wende dich bitte an
						<HokLink
							class="hover:underline hover:text-color-main-hover"
							to="mailto:jobsuche@hokify.com"
							target="_blank"
							is-text
							>jobsuche@hokify.com</HokLink
						>.
					</p>
					<HokButton fullwidth="mobile" to="/pwa" color="main">Weitere Jobs entdecken</HokButton>
				</div>
				<div v-else-if="modalError.code === 'USER_LOCKED'" class="text-center">
					<template v-if="modalError.lockedReason === 'massapplication'">
						<h3>Bewerben vorübergehend nicht möglich</h3>
						<p>
							Auf Grund von ungewöhnlich hoher Aktivität müssen wir dein Profil überprüfen. Aus
							diesem Grund ist es vorübergehend nicht möglich sich zu bewerben. Bitte wende dich an
							<HokLink
								class="hover:underline hover:text-color-main-hover"
								to="mailto:jobsuche@hokify.com"
								target="_blank"
								is-text
								>jobsuche@hokify.com</HokLink
							>
							um das Problem zu beheben.
						</p>
						<HokButton fullwidth="mobile" to="/pwa" color="main">Weitere Jobs entdecken</HokButton>
					</template>
					<template v-else>
						<h3>Dein Account wurde gesperrt</h3>
						<p>
							Bei Fragen melde dich bei unserem Support unter
							<HokLink
								class="hover:underline hover:text-color-main-hover"
								to="mailto:jobsuche@hokify.com"
								target="_blank"
								is-text
								>jobsuche@hokify.com</HokLink
							>.
						</p>
						<HokButton fullwidth="mobile" to="/pwa" color="main">Weitere Jobs entdecken</HokButton>
					</template>
				</div>
				<div v-else class="text-center">
					<h3>
						Leider ist ein Fehler aufgetreten!
						<template v-if="modalError.code"> ({{ modalError.code }}) </template>
					</h3>
					<p v-if="modalError.text">
						{{ modalError.text }}
					</p>
					<HokButton color="main" @click="$modal.hide('general-error')">OK</HokButton>
				</div>
			</div>
		</HokModal>

		<CvModals :show-cv-menu="showCvMenu" @cv-deleted="updateMatch" />

		<Spinner v-if="loading" />
	</div>
</template>

<script lang="ts">
import ProfileImage from '@hokify/shared-components-nuxt3/lib/components/ProfileImage.vue';
import ErrorBox from '@hokify/shared-components-nuxt3/lib/components/ErrorBox.vue';
import HorizontalButtons from '@hokify/shared-components-nuxt3/lib/components/HorizontalButtons.vue';
import HokCheckbox from '@hokify/shared-components-nuxt3/lib/components/HokCheckbox.vue';
import HokInput from '@hokify/shared-components-nuxt3/lib/components/HokInput.vue';
import MatchQuestions from '@hokify/pwa-core-nuxt3/components/match/MatchQuestions.vue';
import { ApplyMode } from '@hokify/pwa-core-nuxt3/helpers/apply';
import ProfileImageEditMode from '@hokify/pwa-core-nuxt3/components/hokifycv/ProfileImageEditMode.vue';
import { fromNow } from '@hokify/shared-components-nuxt3/lib/helpers/datehelpers/from-now';
import { calcAge } from '@hokify/shared-components-nuxt3/lib/helpers/datehelpers/calculate-age';
import type {
	IAPIMatchForJobSeeker,
	IAPILoginUser,
	IInterviewQuestion,
	IAPIUserExtra,
	APITypeObjectId,
	APIObjectType,
	IAPIExitSurvey,
	IAPIMatchForJobSeekerListEntry,
	UserExtraType
} from '@hokify/common';
import EmailOrPhone from '@hokify/shared-components-nuxt3/lib/components/EmailOrPhone.vue';
import { lsTest } from '@hokify/shared-components-nuxt3/lib/helpers/localstorage';
import { getCookie } from '@hokify/shared-components-nuxt3/lib/helpers/cookie';
import Upload from '@hokify/pwa-core-nuxt3/components/Upload.vue';
import { defineComponent, markRaw, defineAsyncComponent } from 'vue';
import type { PropType } from 'vue';
import { errorCodes } from '@hokify/shared-components-nuxt3/lib/data/errorCodes';
import { mapStores } from 'pinia';
import { EventBus } from '@hokify/shared-components-nuxt3/lib/eventbus';
import CvUserGeneralOverview from '@/components/user/hokifyCvEdit/CvUserGeneralOverview.vue';
import { useMatchesStore } from '@/stores/matches';
import { useRelationsStore } from '@/stores/relations';
import { useLoginStore } from '@/stores/login';
import { useSmsStore } from '@/stores/sms';
import { useSurveyStore } from '@/stores/survey';
import { useUserProfileStore } from '@/stores/user-profile';
import { useUserProfileGeneralStore } from '@/stores/user-profile-general';
import { useFeatureFlagStore } from '@/stores/feature-flag';
import CvModals from '../hokifyCvEdit/cvModals.vue';
import CvExperiencesEditMode from '../hokifyCvEdit/CvExperiencesEditMode.vue';
import CvEducationsEditMode from '../hokifyCvEdit/CvEducationsEditMode.vue';
import CvSkillsEditMode from '../hokifyCvEdit/CvSkillsEditMode.vue';

function hasPrepareUploadDocument(obj: any): obj is {
	prepareUploadDocument(
		title: string,
		type: UserExtraType | string,
		forceUpload?: boolean,
		id?: APITypeObjectId<APIObjectType.UserExtra>
	): void;
} {
	return obj && typeof obj.prepareUploadDocument === 'function';
}

function isHTMLElement(element: any): element is HTMLElement {
	return element && element instanceof HTMLElement;
}

export default defineComponent({
	name: 'MatchOverview',
	components: {
		Upload,
		ProfileImageEditMode,
		MatchQuestions,
		ErrorBox,
		HorizontalButtons,
		HokCheckbox,
		CvUserGeneralOverview,
		HokInput,
		ProfileImage,
		CvModals,
		EmailOrPhone,
		CvExperiencesEditMode,
		CvEducationsEditMode,
		CvSkillsEditMode
	},
	emits: ['restart-interview'],
	data() {
		const profileVideoEnabled = false;
		const appliedJobs = undefined as IAPIMatchForJobSeekerListEntry[] | undefined;
		const answerModels: boolean[] = [];
		const exitSurveyAnswers: string[] = [];
		const exitSurvey = undefined as undefined | IAPIExitSurvey;
		const phoneNumber = undefined as undefined | string;
		const retryingApplication: any = undefined;
		const modalError = undefined as
			| {
					text: string;
					code: string;
					unansweredQuestions: { id: string; question: string }[];
					info: { missing: string[] };
					lockedReason: string;
			  }
			| undefined;
		const observer = undefined as undefined | IntersectionObserver;
		const disabledButtons = true;
		const showError = false as boolean;
		const firstApplication = true;
		const protectionActive = false;
		return {
			profileVideoEnabled,
			fromNow,
			calcAge,
			currentlyApplyingFor: '',
			resentVerification: false,
			redirectHandled: false,
			modal: {
				w: this.$isMobile.phone ? '95%' : '500px',
				h: 'auto'
			},
			modalError,
			modalOpen: false,
			loading: false,
			animationDone: false,
			showInvalidCode: false,
			privacyError: false,
			acceptedPrivacyApplication: false,
			verifyCode: '',
			applied: false,
			retryingApplication,
			supportsVideoRecording: false,
			phoneNumber,
			isSending: false,
			showError,
			smsSent: false,
			testSMS: false,
			exitSurvey,
			exitSurveyAnswers,
			answerModels,
			appliedJobs,
			showCvMenu: false,
			internalMatch: this.match,
			errorCodes,
			observer,
			disabledButtons,
			EventBus,
			firstApplication,
			protectionActive
		};
	},
	computed: {
		user(): IAPILoginUser | undefined {
			return this.userProfileStore.obj;
		},
		profileProtectionModal(): boolean {
			return this.firstApplication && !this.protectionActive;
		},
		isCordova() {
			return import.meta.client && process.env.cordova;
		},
		activeHokifyCv(): boolean {
			return this.visibleCv?.details === 'hokify' || false;
		},
		visibleCv(): IAPIUserExtra | undefined {
			return (
				this.userProfileStore.obj?.extras?.find(file => file.type === 'cv' && file.visible) ||
				undefined
			);
		},
		cvUrl(): string | undefined {
			return this.visibleCv?.url;
		},
		companyName(): string {
			return (
				(this.internalMatch?.relation?.type === 'company' &&
					this.internalMatch.relation?.obj?.name) ||
				(this.internalMatch?.relation?.type === 'job' &&
					this.internalMatch.relation?.obj?.company?.name) ||
				'diese Firma'
			);
		},
		hasVideoInProfile() {
			return !!this.videoExtra;
		},
		videoExtra() {
			return this.userProfileStore.obj?.extras?.find(extra => extra.type === 'profile-video');
		},
		cvUploadedFromInterview(): IInterviewQuestion[] {
			return (
				this.internalMatch?.interviewQuestions?.filter(q => {
					if (q.type === 'attachment' && q.module && q.module.toLowerCase() === 'cv') {
						return q;
					}
					return false;
				}) || []
			);
		},
		hasIndividualQuestions(): boolean {
			const questions = this.internalMatch?.interviewQuestions?.filter(
				q => q.type === 'question' && q.showForOverview
			);
			return !!questions?.length;
		},
		hasAttachments(): boolean {
			const interviewAttachments = this.internalMatch?.interviewQuestions
				?.filter(i => i.type === 'attachment' && i.moduleOptions && i.module !== 'cv')
				?.map(i => i?.moduleOptions?.typeId?.toString());
			if (!interviewAttachments || interviewAttachments.length === 0) {
				const attachments =
					this.user &&
					this.user.extras &&
					this.user.extras.filter(
						e => e._id && e.visible && e.type !== 'cv' && e.type !== 'profile-video'
					);
				return !!attachments?.length;
			}
			return !!interviewAttachments?.length;
		},
		hasAttachmentQuestions(): boolean {
			const hasAttachmentQuestions = this.internalMatch?.interviewQuestions?.filter(
				interviewQuestion =>
					interviewQuestion.type === 'attachment' && interviewQuestion.module !== 'cv'
			);
			return !!hasAttachmentQuestions?.length;
		},
		amountMissingAttachments(): number {
			return (
				this.internalMatch?.interviewQuestions?.filter(
					interviewQuestion =>
						interviewQuestion.type === 'attachment' &&
						interviewQuestion.module !== 'cv' &&
						!interviewQuestion.currentAnswer
				).length || 0
			);
		},
		hasMissingAttachments(): boolean {
			return (
				this.internalMatch?.interviewQuestions?.some(
					interviewQuestion =>
						interviewQuestion.type === 'attachment' &&
						!interviewQuestion.currentAnswer &&
						interviewQuestion.module !== 'cv'
				) || false
			);
		},
		hasMissingProfilePicture(): boolean {
			return (
				this.internalMatch?.interviewQuestions?.some(
					interviewQuestion =>
						interviewQuestion.module === 'pic' && !interviewQuestion.currentAnswer
				) || false
			);
		},
		hasMissingProfileVideo(): boolean {
			return (
				this.internalMatch?.interviewQuestions?.some(
					interviewQuestion =>
						interviewQuestion.module === 'profilevideo' && !interviewQuestion.currentAnswer
				) || false
			);
		},
		hasMissingCv(): boolean {
			return (
				this.internalMatch?.interviewQuestions?.some(
					interviewQuestion =>
						interviewQuestion.type === 'attachment' &&
						!interviewQuestion.currentAnswer &&
						interviewQuestion.module === 'cv'
				) || false
			);
		},
		hasFiltersSet(): boolean {
			return !!(
				(this.userProfileStore.obj?.jobFilter?.searchterm ||
					this.userProfileStore.obj?.jobFilter?.fieldIds?.length) &&
				this.userProfileStore.obj?.jobFilter?.location?.name
			);
		},
		accountVerified(): boolean {
			return !!this.userProfileStore.obj?.accountVerified;
		},
		hasCvFails(): boolean {
			return !!this.userProfileStore.obj?.reviewResult?.failedCvCriteria.length;
		},
		...mapStores(
			useFeatureFlagStore,
			useMatchesStore,
			useRelationsStore,
			useLoginStore,
			useSmsStore,
			useSurveyStore,
			useUserProfileStore,
			useUserProfileGeneralStore
		)
	},
	created() {
		this.phoneNumber = this.userProfileStore.obj?.general?.phone;
	},
	async mounted() {
		this.registerIntersectionObserver();

		this.$hokReCaptcha.initializeRecaptchaWidget();

		this.exitSurvey = await this.surveyStore.getSurvey({
			relationId: this.internalMatch.relation?.obj?._id,
			relationType: this.internalMatch.relation?.type,
			audienceId:
				this.internalMatch.relation?.type === 'company' && this.internalMatch.relation?.audienceId
		});

		this.exitSurvey?.answers?.forEach(answer => {
			this.answerModels[answer] = false;
		});

		this.testSMS = await this.featureFlagStore.featureFlagEnabledByName('testSMS');

		this.protectionActive = this.userProfileStore?.obj?.enhancedProtectionActive ?? true;

		// check if video creation is supported by current device
		const mediaElement = document.createElement('video');
		this.supportsVideoRecording =
			!!(mediaElement as any).captureStream || !!(mediaElement as any).mozCaptureStream;

		// tracking for remarketing
		// TODO We need to doublecheck with Marketing if we should really disable this tracking for activesourcing
		// For now activeSourcingMode will only be active on test env
		if (this.internalMatch.relation.type === 'job' && !this.activeSourcingMode) {
			const job = this.internalMatch.relation.obj;
			const country = job?.location?.countryCode
				? job?.location.countryCode.replace(/\W/g, '').toUpperCase()
				: undefined;
			this.$trackUserEvent?.('application_overview', {
				job: {
					name: job?.name,
					jobNr: job?.jobNr,
					_id: job?._id,
					jobfields: job?.fields.map(f => f.name),
					type: job?.type,
					priorize: job?.internal.trackingValue || 1,
					country,
					city: job?.location?.city || undefined
				},
				category: 'user-card-swipe-interaction'
			});
		} else if (this.internalMatch.relation.type === 'company') {
			const company = this.internalMatch.relation.obj;
			const country = company?.location?.[0]?.countryCode
				? company?.location?.[0].countryCode.replace(/\W/g, '').toUpperCase()
				: undefined;
			this.$trackUserEvent?.('application_overview', {
				company: {
					name: company?.name,
					_id: company?._id,
					jobfields: company?.fields?.map(f => f.name),
					country,
					city: company?.location?.[0]?.city || undefined,
					audienceId: this.audienceId
				},
				category: 'user-card-swipe-interaction'
			});
		}
		this.acceptedPrivacyApplication = !!this.userProfileStore.obj?.privacy?.user_application;

		this.appliedJobs = await this.matchesStore.getApplied();

		this.firstApplication = !this.appliedJobs.length;
	},
	beforeUnmount() {
		this.EventBus.$off('end-go-to');
	},
	methods: {
		async resendVerification() {
			const url = `/${this.interviewMode}/${
				(this.internalMatch?.relation?.type === 'job' &&
					`${this.internalMatch.relation.obj.jobNr}`) ||
				(this.internalMatch?.relation?.type === 'company' &&
					`${this.internalMatch.relation.obj._id}`) ||
				undefined
			}`;

			try {
				this.resentVerification = true;

				await this.$hokReCaptcha.sendWithRecaptchaToken(
					this.userProfileStore.sendVerificationCode,
					{
						entryUrl: url
					},
					'verificationCode'
				);

				this.$snack.success({
					text: 'Code erneut versendet!'
				});
			} catch (err: any) {
				if (err.code === 'ALREADY_VERIFIED') {
					this.apply();
				} else {
					this.$nuxt.$errorHandler(err);
				}
			}
		},
		updateExitSurveyAnswers(answer: string) {
			const indexFound = this.exitSurveyAnswers.indexOf(answer);
			if (indexFound >= 0) {
				this.exitSurveyAnswers.splice(indexFound, 1);
			} else {
				this.exitSurveyAnswers.push(answer);
			}
		},
		async handleSendLink() {
			if (this.isSending) {
				return;
			}
			this.isSending = true;

			try {
				await this.userProfileGeneralStore.updateGeneral({ phone: this.phoneNumber });
			} catch (e) {
				console.error(e);
			}

			let data = {
				type: 'hokify-apply-success',
				relation: 'job',
				relationId: this.internalMatch.relation.obj._id
			};

			if (this.internalMatch.relation.type === 'company') {
				data = {
					type: 'hokify-apply-success',
					relation: 'company',
					relationId: this.internalMatch.relation.obj._id
				};
			}

			try {
				await this.smsStore.sendAppLink(data);
				this.showError = false;
				this.smsSent = true;
			} catch (e) {
				this.showError = true;
				this.smsSent = false;
				console.error(e);
			} finally {
				this.isSending = false;
			}
		},
		async doUseHokifyCv() {
			try {
				await this.userProfileStore.useHokifyCv();
				this.$snack.success({
					text: 'Dein hokify Lebenslauf wird jetzt für Bewerbungen benutzt.'
				});
			} catch (err: any) {
				if (
					this.$isHokFetchResponseError(err) &&
					err.response?.data.code === 'INCOMPLETE_HOKIFY_CV'
				) {
					this.$snack.danger({
						text: 'Um fortzufahren muss dein Lebenslauf vollständiger sein!'
					});
					return;
				}
				this.$snack.danger({
					text: 'Es ist ein Fehler aufgetreten, beim Versuch den hokify Lebenslauf zu verwenden.'
				});
				this.$nuxt.$errorHandler(
					err,
					'Es ist ein Fehler aufgetreten, beim Versuch den hokify Lebenslauf zu verwenden.'
				);
			}
		},
		jobSavedOnly() {
			if (this.$page && this.$page.isOpen) {
				this.$page.goBack();
			} else {
				this.$router.push({ path: '/pwa' });
			}
		},
		codeChanged() {
			this.showInvalidCode = false;
		},
		async verifyAccount(e) {
			if (e) {
				e.preventDefault();
			}

			if (!this.verifyCode) {
				this.showInvalidCode = true;
				return;
			}

			try {
				this.loading = true;
				await this.loginStore.verify(this.verifyCode);
				this.$trackGenericEvent?.('user_verified', {});
				this.apply();
			} catch (err: any) {
				this.loading = false;
				this.showInvalidCode = true;
				// this.$nuxt.$errorHandler(err);
			}
		},
		async saveUser() {
			if (this.disabledButtons) {
				this.$snack.danger({
					text: 'Scrolle ans Ende deines Profils, um deine Bewerbung speichern zu können.',
					offset: this.$isMobile.any ? 'bottom-36' : 'bottom-20'
				});
				this.$trackUserEvent?.('view_outro_modal_save', {});
			} else {
				try {
					if (this.user?.cvInfo?.verifiedByUser === false) {
						await this.userProfileStore.verifyParsedCv({ isVerified: true });
					}
				} catch (err) {
					this.$nuxt.$errorHandler(err);
				}
				this.$trackUserEvent?.('save_job_application', {});
				this.$modal.show('job-save-success');
				this.modalOpen = true;
			}
		},
		applyDSGVO() {
			if (!this.acceptedPrivacyApplication) {
				this.privacyError = true;
			} else {
				this.privacyError = false;
				this.apply();
			}
		},
		restartInterview() {
			this.$modal.hide('profile-not-complete');
			this.$emit('restart-interview');
		},
		async apply() {
			if (this.disabledButtons) {
				this.$snack.danger({
					text: 'Scrolle ans Ende deines Profils, um deine Bewerbung absenden zu können.',
					offset: this.$isMobile.any ? 'bottom-36' : 'bottom-20'
				});
				this.$trackUserEvent?.('view_outro_modal_apply', {});
			} else {
				if (!this.acceptedPrivacyApplication || this.privacyError) {
					this.$modal.show('accept-privacy-application');
					return;
				}
				this.$modal.hide('accept-privacy-application');

				if (this.currentlyApplyingFor === this.internalMatch._id) {
					console.warn('application already processing...');
					return;
				}

				if (!this.retryingApplication) {
					this.loading = true;
				}

				this.currentlyApplyingFor = this.internalMatch._id;

				return this.relationsStore
					.applyToRelation({
						relation: this.internalMatch.relation,
						acceptedPrivacyApplication: this.acceptedPrivacyApplication
					})
					.then(async () => {
						if (this.acceptedPrivacyApplication) {
							// CALL UPDATE PRIVACY
							this.userProfileStore.updatePrivacy({ user_application: true });
						}

						try {
							if (this.user?.cvInfo?.verifiedByUser === false) {
								await this.userProfileStore.verifyParsedCv({ isVerified: true });
							}
						} catch (err) {
							this.$nuxt.$errorHandler(err);
						}

						this.applied = true;
						clearInterval(this.retryingApplication);
						this.$modal.hide('user-not-verified');
						this.$modal.hide('profile-not-complete');

						if (this.hasCvFails) {
							this.$modal.show('cv-fails');
						} else {
							this.$modal.show('job-like-success');
						}
					})
					.catch(err => {
						console.error(err);
						if (err.response?._data?.code === 'PRIVACY_NOT_ACCEPTED') {
							this.$modal.show('accept-privacy-application');
						} else if (err.response?._data?.code === 'USER_NOT_VERIFIED') {
							if (this.applied) {
								return;
							}

							if (!this.retryingApplication) {
								this.retryingApplication = setInterval(this.apply, 30000);

								this.$trackUserEvent?.('user_not_verified', {});
							}
							this.$modal.show('user-not-verified');
						} else if (err.response && err.response._data) {
							this.$trackUserEvent?.('incomplete_job_application', {
								relationId: this.internalMatch.relation.obj._id
							});
							clearInterval(this.retryingApplication);
							this.retryingApplication = false;
							this.$modal.hide('user-not-verified');
							console.error('ERROR', err && err.response);
							if (
								err.response._data.code === 'PROFILE_INCOMPLETE' ||
								err.response._data.code === 'PROFILE_JOB_INCOMPLETE'
							) {
								this.showModal('profile-not-complete', {
									fields: err.response._data
								});
							} else if (err.response._data.code === 'ALREADY_APPLIED') {
								this.$modal.show('job-like-success');
							} else {
								this.showModal('general-error', {
									...err.response._data,
									code: err.response._data.code,
									text:
										err.response._data.message ||
										err.response._data.msg ||
										err.response._data.err ||
										err.response._data.error ||
										err.response._data
								});
							}
						} else {
							this.$nuxt.$errorHandler(err);
						}
					})
					.then(() => {
						this.currentlyApplyingFor = '';
						this.loading = false;
					});
			}
		},
		showModal(modal, obj) {
			this.modalOpen = true;
			this.$modal.show(modal, obj);
		},
		modalChange(event) {
			const modalName = event.name;
			const opened = event.state;
			if (opened) {
				this.modalOpen = true;
				if (modalName === 'job-like-success') {
					// successfully applied

					if (this.internalMatch.relation?.type === 'job') {
						const job = this.internalMatch.relation.obj;
						const country = job?.location?.countryCode
							? job?.location.countryCode.replace(/\W/g, '').toUpperCase()
							: undefined;

						this.$trackUserEvent?.('send_application', {
							matchId: this.internalMatch._id,
							job: {
								name: job?.name,
								jobNr: job?.jobNr,
								_id: job?._id,
								jobfields: job?.fields.map(f => f.name),
								type: job?.type,
								priorize: job?.internal.trackingValue || 1,
								country,
								city: job?.location?.city || undefined
							},
							relationId: job?._id,
							item_type: 'job',
							category: 'user-card-swipe-interaction',
							application_type: this.internalMatch?.relation.obj.selectedCandidate
								? 'active-sourcing-ats'
								: 'regular'
						});
					} else if (this.internalMatch.relation?.type === 'company') {
						const company = this.internalMatch?.relation.obj;
						const country = company?.location?.[0].countryCode
							? company?.location?.[0].countryCode.replace(/\W/g, '').toUpperCase()
							: undefined;

						this.$trackUserEvent?.('send_application', {
							matchId: this.internalMatch._id,
							company: {
								name: company?.name,
								_id: company?._id,
								jobfields: company?.fields?.map(f => f.name),
								country,
								city: company?.location?.[0]?.city || undefined,
								audienceId: this.audienceId
							},
							relationId: company?._id,
							item_type: 'company',
							category: 'user-card-swipe-interaction'
						});
					}

					if (import.meta.client) {
						if (window.parent.postMessage) {
							window.parent.postMessage('application-sent', '*');
						} else if (window.postMessage) {
							window.postMessage('application-sent', '*');
						}
					}
				}
				document.body.classList.add('noscroll');
				setTimeout(() => {
					this.animationDone = true;
				}, 3000);
			} else {
				if (this.retryingApplication) {
					clearInterval(this.retryingApplication);
					this.retryingApplication = false;
				}
				this.modalOpen = false;
				document.body.classList.remove('noscroll');
				if (modalName === 'job-like-success' && !this.redirectHandled) {
					// redirect after close
					if (this.profileProtectionModal) {
						this.$router.push({ path: '/hokifycv' });
					} else {
						this.$router.push({ path: '/pwa' });
					}
				}
			}
		},
		maybePreventClosing(event) {
			const modalName = event.name;
			// const opened = event.state;
			if (modalName === 'job-like-success') {
				if (!this.animationDone) {
					event.stop();
				}
			}
		},
		setModalError(event) {
			// console.log('setModalError', event.params);
			// this is used for e.g. unansweredQuestions
			if (event?.params?.fields) {
				this.modalError = event.params.fields;
			} else if (event?.params) {
				this.modalError = event.params;
			}
		},
		answerExitSurvey() {
			if (this.exitSurvey && this.exitSurveyAnswers?.length) {
				const cookies = import.meta.client && window.document.cookie;
				const utm = (cookies && getCookie('utm', cookies)) || undefined;

				this.surveyStore.answerSurvey({
					relationId: this.internalMatch.relation?.obj?._id,
					relationType: this.internalMatch.relation?.type,
					userAnswers: this.exitSurveyAnswers,
					surveyId: this.exitSurvey?._id,
					utm
				});
			}

			this.exitSurvey = undefined;
		},
		applicationCompleted(gotoApplicationOverview = false) {
			this.$modal.hide('job-like-success');
			if (lsTest()) {
				localStorage.setItem(`hasSeenJobLikeSuccess`, 'true');
			}

			if (gotoApplicationOverview) {
				this.redirectHandled = true;
				this.$router.push({ path: `/pwa/match/${this.internalMatch._id}?showTab=application` });
			} else if (this.$page?.isOpen) {
				this.$page.goBack();
			} else if (!this.hasFiltersSet) {
				this.$router.push({ path: '/pwa/onboarding/welcome' });
			} else {
				this.$router.push({ path: '/pwa' });
			}
		},
		async openVideoApplication() {
			const videoApplicationComponent = markRaw(
				defineAsyncComponent(() => import('@/pages/pwa/videoUploadPage.vue'))
			);
			this.$trackUserEvent?.('view_video_application', {});
			const pageTitle = 'Videovorstellung';
			try {
				await this.$page.push(
					videoApplicationComponent,
					{ videoTrackingCategory: 'user-profile' },
					{
						pageTitle,
						name: 'videoApplication',
						done: () => {
							this.EventBus.$off('end-go-to');
						}
					}
				);
				this.EventBus.$on('end-go-to', () => this.$page.goBack());
			} catch (err) {
				this.$nuxt.$errorHandler(err);
			}
		},
		setCv(cvType?: any) {
			if (cvType === 'new-upload') {
				if (hasPrepareUploadDocument(this.$refs.upload)) {
					this.$refs.upload.prepareUploadDocument('Lebenslauf', 'cv');
				}
			} else if (cvType === 'hokify') {
				this.doUseHokifyCv();
			}
		},
		async updateMatch() {
			this.showCvMenu = false;
			const match = await this.matchesStore.getUserMatchByRelation({
				relation: this.match.relation,
				force: true
			});
			if (match) {
				this.internalMatch = match;
			}
		},
		watchChanges(isVerified) {
			if (isVerified && this.retryingApplication) {
				this.apply();
			}
		},
		registerIntersectionObserver() {
			this.observer = new IntersectionObserver(this.isVisible, { threshold: 0.75 });
			this.observeElements();
		},
		observeElements() {
			const element = document.querySelector('#cv-observer-element');
			if (element) {
				this.observer?.observe(element);
			}
		},
		isVisible(entries) {
			if (entries.some(entry => entry.isIntersecting)) {
				this.disabledButtons = false;
			}
		},
		toggleMenu() {
			if (!this.viewOnly) {
				this.showCvMenu = !this.showCvMenu;
			} else {
				window.open(this.cvUrl, '_blank');
			}
		},
		changeContact() {
			this.$modal.hide('user-not-verified');
			this.$nextTick(() => {
				const element = document.querySelector(`#cv-user-general-overview`);
				if (isHTMLElement(element)) {
					element?.scrollIntoView({ behavior: 'smooth', block: 'start' });
				}
			});
		},
		async protectProfile() {
			if (this.user?._id) {
				try {
					await this.loginStore.getNewNonce(this.user?._id);
					this.$router.push('/pwa/password?protectProfile=interview');
				} catch (err) {
					this.$nuxt.$errorHandler(err);
				}
			}
		}
	},
	props: {
		match: { type: Object as PropType<IAPIMatchForJobSeeker>, default: null },
		alreadyApplied: { type: Boolean, default: false },
		interviewMode: { type: String as PropType<ApplyMode>, default: '' },
		viewOnly: { type: Boolean, default: true },
		audienceId: {
			type: String as PropType<APITypeObjectId<APIObjectType.CompanyAudience>>,
			required: false,
			default: ''
		},
		activeSourcingMode: { type: Boolean, default: false }
	},
	watch: {
		accountVerified: [
			{
				handler: 'watchChanges'
			}
		]
	}
});
</script>

<style scoped src="@hokify/pwa-core-nuxt3/assets/styles/match-overview.scss" lang="scss"></style>
