<template>
	<div>
		<div
			v-if="
				questions.length > 0 ||
				(type === 'attachment' && user && user.extras && user.extras.length > 0)
			"
			:class="{ 'hokbox md:m-0 md:mb-5': styling === 'company' }"
			class="match-questions"
		>
			<h2 v-if="heading" class="mb-3 border-b-2 border-color-text md:inline-block uppercase">
				{{ heading }}
			</h2>
			<ul :class="`${type}s ${qkey}`">
				<!-- at first we list all interviewQuestions-->
				<li
					v-for="q in questions"
					:key="q.identifier"
					:data-id="q.identifier"
					:class="[
						typeof q.currentAnswer === 'undefined' ? 'alert' : '',
						profileOrMatchOverview
							? 'border-b border-color-grey-lightest mb-8 pb-6 last:border-none last:mb-0'
							: 'flex items-center mb-6'
					]"
				>
					<MatchQuestion
						:q="q"
						:type="type"
						:view-only="viewOnly"
						:beautify-text="beautifyText"
						:profile-or-match-overview="profileOrMatchOverview"
						@click="!viewOnly && editAnswer(q.identifier, $event)"
					/>
				</li>
				<!-- then we additionally list all attachments, which are saved in user-profile already but are NOT already listed in interviewQuestions -->
				<template v-if="user && type === 'attachment' && showAttachments">
					<li
						v-for="att in attachments"
						:key="att._id"
						class="mb-4"
						:data-id="att._id"
						:data-doc-type="att.type"
						:class="[
							profileOrMatchOverview
								? 'border-b border-color-grey-lightest mb-8 pb-6 last:border-none last:mb-0'
								: 'flex items-center mb-6',
							{ 'cursor-pointer': !viewOnly }
						]"
						@click="!viewOnly && editExtra(att)"
					>
						<AttachmentQuestion :view-only="viewOnly" :attachment="att" profile-or-match-overview />
					</li>
				</template>
			</ul>
			<HokModal
				v-if="!viewOnly"
				:classes="['v--modal', 'modal-question']"
				:name="modalName"
				:adaptive="true"
				:width="modal.w"
				:height="modal.h"
				@opened="modalChange"
				@closed="modalChange"
			>
				<template v-if="msg">
					<h3>{{ msg.question }}</h3>
					<ErrorBox v-if="msg.lastError">
						<template v-if="errorCodes.genericErrors[`${msg.lastError.code}_TITLE`]" #title>
							{{ errorCodes.genericErrors[`${msg.lastError.code}_TITLE`] }}
						</template>

						{{
							errorCodes.genericErrors[msg.lastError.code] ||
							(msg.lastError && msg.lastError.code) ||
							msg.lastError
						}}
					</ErrorBox>
					<InterviewAnswer
						v-model="internalAnswer"
						:match-obj-id="match && match?.relation && match.relation.obj._id"
						:msg-index="0"
						:send-answer="sendAnswer"
						:modal="modal"
						:view-only="viewOnly"
						is-match-overview
					/>
				</template>
			</HokModal>
			<Spinner v-if="loading" />
		</div>
		<!--EDIT DOCUMENT MODAL-->
		<!-- the same modal is used in CvDocumentsEditMode.vue-->
		<HokModal :adaptive="false" name="editdocument">
			<div v-if="editDocumentObj">
				<h3 class="text-center">
					{{ editDocumentObj.title }}
				</h3>

				<p class="mb-8 text-xs text-center">Hochgeladen {{ fromNow(editDocumentObj.date) }}</p>
				<HorizontalButtons>
					<template #left>
						<HokButton
							fullwidth="mobile"
							color="purple"
							@click="deleteExtra(editDocumentObj._id, editDocumentObj.title)"
						>
							Löschen
						</HokButton>
					</template>
					<template #right>
						<HokButton fullwidth="mobile" color="main" @click="openFile(editDocumentObj)">
							Anzeigen
						</HokButton>
					</template>
				</HorizontalButtons>
			</div>
		</HokModal>
	</div>
</template>

<script lang="ts">
import ErrorBox from '@hokify/shared-components-nuxt3/lib/components/ErrorBox.vue';
// TODO move into shared components
import HorizontalButtons from '@hokify/shared-components-nuxt3/lib/components/HorizontalButtons.vue';
import { fromNow } from '@hokify/shared-components-nuxt3/lib/helpers/datehelpers/from-now';
import type {
	IAPIUserExtra,
	APITypeObjectId,
	IAPIMatchForJobSeeker,
	IAPILoginUser,
	IInterviewQuestion,
	APIObjectType
} from '@hokify/common';
import { defineComponent } from 'vue';
import type { PropType } from 'vue';
import { errorCodes } from '@hokify/shared-components-nuxt3/lib/data/errorCodes';
import AttachmentQuestion from './AttachmentQuestion.vue';
import MatchQuestion from './MatchQuestion.vue';
import InterviewAnswer from '@/components/user/interviewAnswer/InterviewAnswer.vue';

import type { IPWAInterviewQuestion } from '../../helpers/apply';

export default defineComponent({
	name: 'MatchQuestions',
	components: {
		InterviewAnswer,
		AttachmentQuestion,
		ErrorBox,
		MatchQuestion,
		HorizontalButtons
	},
	emits: ['empty-url', 'update-match'],
	data() {
		const editDocumentObj = null as any;
		const currentUploadStatus = false as boolean | number;
		const msg = undefined as IPWAInterviewQuestion | undefined;
		const modalName = '';
		const loading = false;

		return {
			msg,
			currentUploadStatus,
			editDocumentObj,
			modalName,
			loading,
			fromNow,
			missingCv:
				'Bitte lade deinen Lebenslauf hoch oder erstelle schnell und einfach deinen hokify-Lebenslauf.',
			errorCodes
		};
	},
	computed: {
		internalAnswer: {
			get() {
				return this.msg;
			},
			set(newValue) {
				this.msg.currentAnswer = newValue;
			}
		},
		storeUser(): IAPILoginUser | undefined {
			return this.$nuxt?.$userProfileStore?.obj;
		},
		internalUser(): IAPILoginUser | undefined {
			if (this.viewOnly && this.user) {
				return this.user; // return user provided via properties (only if viewOnly is true)
			}
			return this.storeUser; // use user obj from store
		},
		attachments(): IAPIUserExtra[] {
			const interviewAttachments =
				this.match?.interviewQuestions
					?.filter(i => i.type === 'attachment' && i.moduleOptions)
					?.map(i => i?.moduleOptions?.typeId?.toString()) || [];
			return (
				this.internalUser?.extras?.filter(
					e =>
						e._id &&
						e.visible &&
						!interviewAttachments.includes(e._id.toString()) &&
						(!this.exclModules || !this.exclModules.includes(e.type && e.type.toLowerCase()))
				) || []
			);
		},
		questions(): IInterviewQuestion[] {
			return (
				this.match?.interviewQuestions
					?.filter(
						q =>
							q.type === this.type &&
							(!this.modules || this.modules.includes(q.module?.toLowerCase())) &&
							(!this.exclModules || !this.exclModules.includes(q.module?.toLowerCase())) &&
							q.showForOverview !== false
					)
					.sort((q1, q2) => {
						if (q1.module?.toLowerCase() === 'commonyesno') {
							return -1;
						}
						if (q2.module?.toLowerCase() === 'commonyesno') {
							return 1;
						}
						return (
							(q2.module && (q2.module.toLowerCase() as any)) -
							(q1.module && (q1.module.toLowerCase() as any))
						);
					}) || []
			);
		}
	},
	created() {
		this.modalName = `answer-question-${this.qkey || ''}-${this.type || ''}`;
	},
	methods: {
		editExtra(extra) {
			this.editDocumentObj = extra;
			this.$modal.show('editdocument');
		},
		openFile(extra) {
			if (extra.url !== '') {
				this.$openLink.call(this, extra.title, extra.url, 'media-detail');
			} else {
				this.$emit('empty-url');
			}
		},
		deleteExtra(id, title) {
			this.delExtra(id)?.then(() => {
				this.$modal.hide('editdocument');
				this.$snack.success({
					text: `${title} wurde erfolgreich gelöscht.`
				});
			});
		},
		editAnswer(qId, e) {
			if (e) {
				e.preventDefault();
			}
			if (!qId) {
				throw new Error('no question id!');
			}
			this.loading = true;

			this.answerOptions({
				matchObj: this.match,
				qId
			})
				?.then(data => {
					// data.editing = true;
					this.msg = data;
					if (this.msg) {
						this.$modal.show(this.modalName);
					}
				})
				.catch(err => this.$nuxt.$errorHandler(err))
				.then(() => {
					this.loading = false;
				});
		},
		sendAnswer(msgId, answers, _reload, value) {
			this.loading = true;

			const isUpload =
				typeof answers.has === 'function' ? answers.has('media') : typeof value !== 'string';
			return this.answerInterview({
				form: answers,
				msgId,
				matchObj: this.match,
				onUploadProgress: evt => {
					if (isUpload && evt.total) {
						this.currentUploadStatus = Math.round((evt.loaded / evt.total) * 100);
					}
				},
				replace: true
			})
				?.then(data => {
					this.loading = false;
					const { success, answered, next } = data;
					if (success) {
						if (answered) {
							if (next) {
								this.msg = next;
							} else {
								this.$modal.hide(this.modalName);
								this.$emit('update-match');
							}
						}
					}
				})
				.catch(err => {
					this.loading = false;
					if (err.response && err.response.data && this.msg) {
						this.msg.lastError = err.response.data;
					} else {
						this.$nuxt.$errorHandler(err);
					}
				});
		},
		modalChange(event) {
			const opened = event.state;
			if (opened) {
				document.body.classList.add('noscroll');
			} else {
				document.body.classList.remove('noscroll');
			}
		},
		delExtra(id: APITypeObjectId<APIObjectType.UserExtra>):
			| Promise<{
					userUpdate: Record<string, unknown>;
			  }>
			| undefined {
			return this.$nuxt?.$userProfileExtraStore?.delExtra(id);
		},
		answerInterview(payload) {
			return this.$nuxt?.$interviewStore?.answerInterview(payload);
		},
		answerOptions(payload) {
			return this.$nuxt?.$interviewStore?.answerOptions(payload);
		}
	},
	props: {
		match: { type: Object as PropType<IAPIMatchForJobSeeker | null>, default: null },
		modal: { type: Object, default: () => ({}) },
		user: { type: Object as PropType<IAPILoginUser>, default: () => ({}) },
		type: { type: String, default: 'question' },
		modules: { type: Array, default: null },
		exclModules: { type: Array, default: null },
		heading: { type: String, default: '' },
		qkey: { type: String, default: '' },
		styling: { type: String, default: '' },
		viewOnly: { type: Boolean, default: false },
		showAttachments: { type: Boolean, default: true },
		beautifyText: { type: Boolean, default: false },
		profileOrMatchOverview: { type: Boolean, default: false }
	}
});
</script>
<style scoped src="../../assets/styles/match-questions.scss" lang="scss"></style>
